export const createUser = async (user) => {
  return await fetch(`${process.env.REACT_APP_POST_URL}/auth/createUser`, {
    method: 'post', headers: {
      'Content-Type': 'application/json'
    }, body: JSON.stringify(user)
  }).then(res => res.json());
}

export const sendSmsCode = async (phoneNumber) => {
  return await fetch(`${process.env.REACT_APP_POST_URL}/auth/sendSmsCode`, {
    method: 'post', headers: {
      'Content-Type': 'application/json'
    }, body: JSON.stringify({ phoneNumber })
  }).then(res => res.json());
}

export const verifySmsCode = async (phoneNumber, code) => {
  return await fetch(`${process.env.REACT_APP_POST_URL}/auth/verifySmsCode`, {
    method: 'post', headers: {
      'Content-Type': 'application/json'
    }, body: JSON.stringify({ phoneNumber, code })
  }).then(res => res.json());
}

export const checkIfUsernameExists = async (username) => {
  return await fetch(`${process.env.REACT_APP_POST_URL}/auth/checkIfUsernameExists/${username}`, {
    method: 'get'
  }).then(res => res.json())
    .then(result => result.result);
}

export const checkIfPhoneExists = async (phone) => {
  return await fetch(`${process.env.REACT_APP_POST_URL}/auth/checkIfPhoneExists/${phone}`, {
    method: 'get'
  }).then(res => res.json())
    .then(result => result.result);
}

export const renewToken = async (token) => {
  await fetch(`${process.env.REACT_APP_POST_URL}/auth/renewToken`, {
    method: 'get', headers: {
      'Authorization': `Bearer ${token}`
    }
  });
}
