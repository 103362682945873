import { Link, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../App';

const Welcome = () => {
  const { user, setUser, setToken } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/login");
  }

  const handleLogout = () => {
    setUser(undefined);
    setToken(undefined);
    localStorage.removeItem("token");
  }

  return (<div className="welcome">
    <h1>Welcome to Poebos</h1>
    {user && <Link to={user} relative="path">My poebos</Link>}
    {!user && <button onClick={handleLogin}>Login</button>}
    {user && <button onClick={handleLogout}>Logout</button>}
  </div>);
}

export default Welcome;
