import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createPost } from '../api/post-api';
import { AuthContext } from '../App';

const Editor = () => {
  const { user, token } = useContext(AuthContext);
  const [header, setHeader] = useState('');
  const [body, setBody] = useState('');

  const navigate = useNavigate();

  const handleChange = setter => {
    return (event) => setter(event.target.value);
  }

  const submit = async () => {
    await createPost({ header, body }, token);
    navigate("/" + user);
  }

  return (<div>
    <h1>{user} blog</h1>
    <label>Header</label>
    <input type="text" onChange={handleChange(setHeader)}/>
    <label>Body</label>
    <textarea cols="30" rows="10" onChange={handleChange(setBody)}/>
    <button onClick={submit}>Submit</button>
  </div>)
}

export default Editor;
