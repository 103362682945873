import { Link, Outlet, useLoaderData, useParams } from 'react-router-dom';
import { formatDate } from '../util';

const BlogList = () => {
  const posts = useLoaderData();

  let { user } = useParams();

  return (<div>
    <h1>{user}</h1>
    {!posts?.length && "User not found or there are no posts"}
    {posts?.length !== 0 && <>
      <ol reversed>
        {posts.map(post => (<li key={post.number}>
          <Link to={post.number + ''} relative="route">{post.header}&nbsp;</Link>
          <i>{formatDate(post.createdAt)}</i>
        </li>))}
      </ol>
      <Link to={`new/${posts.length ? posts[posts.length - 1].number + 1 : 1}`}>
        Add post
      </Link>
      <hr/>
      <Outlet context={[posts]}/>
    </>}
  </div>)
}

export default BlogList;
