import { Link, useOutletContext, useParams } from 'react-router-dom';

const Post = () => {
  const [posts] = useOutletContext();
  const activePostNumber = parseInt(useParams().post);

  const post = posts.find(p => p['number'] === activePostNumber);

  return (<div>
      <Link to=".." relative="path">X</Link>
      <h3>{post.header}</h3>
      <p>{post.body}</p>
    </div>)
}

export default Post;
