export const getPosts = async (username) => {
  return await fetch(`${process.env.REACT_APP_POST_URL}/post/byUsername/${username}`)
    .then(res => res.json());
}

export const createPost = async (post, token) => {
  await fetch(`${process.env.REACT_APP_POST_URL}/post`, {
    method: 'post', headers: {
      'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
    }, body: JSON.stringify(post)
  });
}
