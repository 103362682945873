import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import BlogList from './blog/BlogList';
import Welcome from './welcome/Welcome';
import Post from './blog/Post';
import Editor from './editor/Editor';
import { getPosts } from './api/post-api';
import Login from './login/Login';
import { createContext, useState } from 'react';
import jwt from 'jwt-decode';

const router = createBrowserRouter([{
  path: "/", element: <Welcome/>,
}, {
  path: "/:user", element: <BlogList/>, children: [{
    path: "/:user/:post", element: <Post/>,
  }], loader: async ({ params }) => {
    return getPosts(params.user);
  },
}, {
  path: "/:user/new/:post", element: <Editor/>,
}, {
  path: "/login", element: <Login/>,
}]);

export const AuthContext = createContext(null);

const App = () => {
  const existingToken = localStorage.getItem("token");
  const [token, setToken] = useState(() => existingToken);
  const [user, setUser] = useState(() => existingToken ? jwt(existingToken).iss : null);

  return (<AuthContext.Provider value={{ user, setUser, token, setToken }}>
    <RouterProvider router={router}/>
  </AuthContext.Provider>);
}

export default App;
