import './Login.css';
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { checkIfPhoneExists, createUser, sendSmsCode, verifySmsCode } from '../api/auth-api';
import jwt from 'jwt-decode'
import { AuthContext } from '../App';
import Spinner from '../components/Spinner';

const Login = (props) => {
  const { setUser, setToken } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false)
  const [isPhoneExists, setIsPhoneExists] = useState(undefined);
  const [phase, setPhase] = useState("phone") // phone -> code if login or phone -> username -> code
  const [phone, setPhone] = useState("")
  const [phoneError, setPhoneError] = useState("")
  const [code, setCode] = useState("")
  const [codeError, setCodeError] = useState("")
  const [username, setUsername] = useState("")
  const [usernameError, setUsernameError] = useState("")

  const navigate = useNavigate();

  const handlePhoneChange = async (value) => {
    setPhone(value);
    if (value.length === 13) {
      setIsLoading(true);
      checkIfPhoneExists(value)
        .then(exists => {
          setIsLoading(false);
          setIsPhoneExists(exists);
        })
        .catch(console.error);
    }
  }

  const handleSendSms = async () => {
    setIsLoading(true);
    sendSmsCode(phone)
      .then(() => {
        setIsLoading(false);
        setPhase("code");
      })
      .catch(console.error);
  }

  const handleGoToUsernameCheck = async () => {
    setPhase('username')
  }

  const handleRegister = () => {
    setIsLoading(true);
    createUser({ username: username, phone: phone })
      .then((resp) => {
        handleSendSms().then();
        setIsLoading(false);
        setPhase("code");
      })
      .catch(console.error)
  }

  const handleVerifySms = async () => {
    setIsLoading(true);
    verifySmsCode(phone, code)
      .then((resp) => {
        setIsLoading(false);
        const user = jwt(resp.token).iss;
        setUser(user);
        setToken(resp.token);
        localStorage.setItem("token", resp.token);
        navigate(`/${user}`);
      })
      .catch(console.log);
  }

  return <div className={"mainContainer"}>
    <div className={"titleContainer"}>
      <div>Login</div>
    </div>
    <br/>
    {phase === 'phone' && (<>
      <div className={"inputContainer"}>
        <input
          value={phone}
          placeholder="Enter your phone here"
          onChange={ev => handlePhoneChange(ev.target.value)}
          className={"inputBox"}/>
        <label className="errorLabel">{phoneError}</label>
      </div>
      <br/>
      <br/>
      {isLoading && <Spinner/>}
      {!isLoading && isPhoneExists === true && <div className={"inputContainer"}>
        <input
          className={"inputButton"}
          type="button"
          onClick={handleSendSms}
          value={"Next"}/>
      </div>}
      {!isLoading && isPhoneExists === false && <div className={"inputContainer"}>
        <input
          className={"inputButton"}
          type="button"
          onClick={handleGoToUsernameCheck}
          value={"Next"}/>
      </div>}
    </>)}
    {phase == 'username' && (<>
      <div className={"inputContainer"}>
        <input
          value={username}
          placeholder="Enter your username here"
          onChange={ev => setUsername(ev.target.value)}
          className={"inputBox"}/>
        <label className="errorLabel">{usernameError}</label>
      </div>
      <br/>
      <div className={"inputContainer"}>
        <input
          className={"inputButton"}
          type="button"
          onClick={handleRegister}
          value={"Register"}/>
      </div>
    </>)}
    {phase == 'code' && (<>
      <div className={"inputContainer"}>
        <input
          value={code}
          placeholder="Enter your code here"
          onChange={ev => setCode(ev.target.value)}
          className={"inputBox"}/>
        <label className="errorLabel">{codeError}</label>
      </div>
      <br/>
      <br/>
      <div className={"inputContainer"}>
        <input
          className={"inputButton"}
          type="button"
          onClick={handleVerifySms}
          value={"Login"}/>
      </div>
    </>)}
  </div>
}

export default Login
